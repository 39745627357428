import { Component, OnInit, Inject } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../services/auth.service';
import { BaseDialogData } from '../../../../models/bases/base-dialog-data';
import { BaseApiResponse } from '../../../../models/bases/base-api-response';
import { Utils } from '../../../../utils/utils';

@Component({
    selector: 'app-user-change-pwd-form',
    templateUrl: './user-change-pwd-form.component.html',
    styleUrl: './user-change-pwd-form.component.scss',
})
export class UserChangePwdFormComponent implements OnInit {
    form: FormGroup;
    showOldPwd: boolean = false;
    showNewPwd: boolean = false;
    showPwd: boolean = false;
    isSubmitting: boolean = false;
    isResetUserPwd: boolean = false;

    constructor(
        readonly _formBuilder: FormBuilder,
        readonly _dialogRef: MatDialogRef<UserChangePwdFormComponent>,
        readonly _userService: UserService,
        readonly _authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public dialogData: BaseDialogData,
        readonly _utils: Utils
    ) {}

    ngOnInit(): void {
        if (this.dialogData.isForUpdate) {
            this.isResetUserPwd = true;
            this.form = this._formBuilder.group({
                password: new FormControl('', [
                    Validators.required,
                    Validators.minLength(6),
                ]),
            });
        } else {
            this.isResetUserPwd = false;
            this.form = this._formBuilder.group({
                oldPassword: new FormControl('', [
                    Validators.required,
                    Validators.minLength(6),
                ]),
                newPassword: new FormControl('', [
                    Validators.required,
                    Validators.minLength(6),
                ]),
            });
        }
    }
    submitHandler = async (form: FormGroup) => {
        try {
            if (form.valid) {
                this.isSubmitting = true;
                let res: BaseApiResponse<any>;
                if (this.isResetUserPwd) {
                    const jsonData = {
                        password: form.value.password,
                    };
                    res = await this._userService.updateUserPassword(
                        this.dialogData.itemId,
                        jsonData
                    );
                } else {
                    const jsonData = {
                        oldPassword: form.value.oldPassword,
                        newPassword: form.value.newPassword,
                    };
                    res = await this._authService.changeOwnPwd(jsonData);
                }
                this.isSubmitting = false;
                if (res) {
                    this._dialogRef.close({ succeeded: true });
                } else {
                    console.error('can not update pwd');
                }
            } else {
                form.markAllAsTouched();
            }
        } catch (error) {
            console.error('changeOwnPwd error =>', error);
            this.isSubmitting = false;
        }
    };

    toggleShowOldPassword = () => {
        this.showOldPwd = !this.showOldPwd;
    };
    toggleShowNewPassword = () => {
        this.showNewPwd = !this.showNewPwd;
    };
    toggleShowPassword = () => {
        this.showPwd = !this.showPwd;
    };
    closeDialog() {
        this._dialogRef.close();
    }
}
