import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    FormsModule,
    FormGroupDirective,
    FormArray,
} from '@angular/forms';
import { InputFieldComponent } from '../../shared/components/input-field/input-field.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { Utils } from '../../utils/utils';
import { SessionService } from '../../services/session.service';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import { Session } from '../../models/session';
import { BaseQuery } from '../../models/query/base-query';
import { map, Observable, of, startWith } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { BookMeetingDialogComponent } from '../../shared/components/book-meeting-dialog/book-meeting-dialog.component';

@Component({
    selector: 'app-meeting-page',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        InputFieldComponent,
        MatDatepickerModule,
        MatSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        DatePipe,
        MatAutocompleteModule,
        AsyncPipe,
        FormsModule,
        MatChipsModule,
        NgxMatTimepickerModule,
        CommonModule,
    ],
    providers: [CustomSnackbarComponent, BookMeetingDialogComponent],
    templateUrl: './meeting-page.component.html',
    styleUrl: './meeting-page.component.scss',
})
export class MeetingPageComponent implements OnInit {
    @ViewChild(FormGroupDirective, { static: false })
    formGroupDirective: FormGroupDirective;

    @Input() min: any;

    sessionQuery: BaseQuery = {
        date: new Date(),
        organization: '',
        room: '',
    };

    organizaerQuery: BaseQuery = {
        organization: '',
    };

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        readonly _utils: Utils,
        readonly _sessionService: SessionService,
        readonly _snackBar: CustomSnackbarComponent,
        readonly _translateService: TranslateService,
        readonly _announcer: LiveAnnouncer,
        readonly _router: Router,
        readonly _dialog: MatDialog
    ) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
        iconRegistry.addSvgIcon(
            'delete',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/images/delete.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'emptyz',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/images/empty.svg')
        );
    }

    form: FormGroup;
    meetings: Session[] = [];
    currentDateTime: Date = new Date();
    startTime: Date;
    endTime: Date;
    endDateTimeValidation: Date;

    organizers: string[] = [];
    filterdOrganizer: Observable<string[]> = of([]);
    organizerControl = new FormControl('', [Validators.required]);

    isLoading: boolean = true;
    hasFetched: boolean = false;

    routes: string[] = [];

    currentTime: string;
    currentTimeAddOneHour: string;

    ngOnInit(): void {
        this.handleGetRoutes();
        this.getCurrentTime();

        this.form = new FormGroup({
            startTime: new FormControl(this.currentTime, [Validators.required]),
            endTime: new FormControl(this.addOneHour(this.currentTime), [
                Validators.required,
            ]),
            startDateTime: new FormControl(this.currentDateTime, [
                Validators.required,
            ]),
            subject: new FormControl('', [Validators.required]),
            organizer: new FormControl(''),
            endDateTime: new FormControl(
                this.dateTimeAddOneHour(this.currentDateTime)
            ),
            participants: new FormArray([]),
        });

        this.handleSessionQueryChange();
        this.handleOrganizerQueryChange();

        this.handleStartDateTimeChange();
        this.handleStartTimeChange();
        this.handleSessionsQuery();
        this.getOrganizers();
    }

    addParticipant(): void {
        this.participants.insert(0, this.createParticipant());
    }

    createParticipant() {
        return new FormGroup({
            email: new FormControl('', [Validators.email]),
        });
    }

    removeParticipant(i) {
        if (this.participants.length > 0) {
            this.participants.removeAt(i);
        }
    }

    get participants() {
        return this.form.get('participants') as FormArray;
    }

    getCurrentTime() {
        const now = new Date();
        const hour = now.getHours();
        const minute = now.getMinutes();
        const time = `${hour}:${minute}`;

        this.currentTime = this.roundTimeToNextTenMinutes(time);
    }

    handleGetRoutes(): void {
        const currentUrl = this._router.url;
        const basePath = currentUrl.split('?')[0];
        this.routes = basePath.split('/').filter((segment) => segment);
    }

    async getSessions(query: any) {
        this.isLoading = true;
        try {
            const res = await this._sessionService.getPublicSessions(query);
            if (res && this._utils.isListData<Session>(res)) {
                this.meetings = res.list;
            } else {
                this.meetings = [];
            }
        } catch (error) {
            console.error(error);
            this.meetings = [];
        } finally {
            this.isLoading = false;
            this.hasFetched = true;
        }
    }

    handleSessionQueryChange() {
        this.sessionQuery = {
            ...this.sessionQuery,
            room: this.getDeviceName(this.routes[2]),
            organization: this.routes[1],
        };
    }

    handleOrganizerQueryChange() {
        this.organizaerQuery = {
            organization: this.routes[1],
        };
    }

    handleSessionsQuery() {
        if (!this.form.get('startDateTime').value) {
            this.sessionQuery['date'] = this.currentDateTime.toISOString();

            this.getSessions(this.sessionQuery);
        } else {
            this.sessionQuery['date'] = new Date().toISOString();

            this.getSessions(this.sessionQuery);
        }
    }

    handleStartDateTimeChange() {
        this.form.controls.startDateTime.valueChanges.subscribe(
            (date: Date) => {
                if (date) {
                    this.sessionQuery.date = date;
                    this.getSessions(this.sessionQuery);

                    const now = new Date();
                    const currentDate = now.getDate();
                    const selectedDateIsoString = new Date(date);
                    const selectedDate = selectedDateIsoString.getDate();

                    if (currentDate !== selectedDate) {
                        this.currentTime = '00:00';
                    } else {
                        const now = new Date();
                        const hour = now.getHours();
                        const minute = now.getMinutes();

                        this.currentTime = `${hour}:${minute}`;
                    }
                }
            }
        );
    }

    handleStartTimeChange(): void {
        this.form.controls.startTime.valueChanges.subscribe(
            (startTime: string) => {
                if (startTime) {
                    const startDate =
                        this.form.controls.startDateTime.value ||
                        this.currentDateTime;
                    const dateTime = this.combineDateAndTime(
                        startDate,
                        startTime
                    );

                    this.form.controls.startDateTime.setValue(dateTime);
                    this.form.controls.endTime.setValue(
                        this.addOneHour(startTime)
                    );
                    this.checkConflictMeeting(dateTime, true);
                }
            }
        );

        this.form.controls.endTime.valueChanges.subscribe((endTime: string) => {
            if (endTime) {
                const endDate =
                    this.form.controls.startDateTime.value ||
                    this.currentDateTime;
                const dateTime = this.combineDateAndTime(endDate, endTime);

                this.form.controls.endDateTime.setValue(dateTime);

                this.checkConflictMeeting(dateTime, false);
            }
        });
    }

    combineDateAndTime(date: Date, time: string): Date {
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const combinedDate = new Date(date);
        combinedDate.setHours(hours, minutes, 0, 0);
        return combinedDate;
    }

    checkConflictMeeting(selectedDate: Date, forStartTime: boolean) {
        if (!selectedDate) return;

        const selectedTime = selectedDate.getTime();

        this.meetings.forEach((meeting) => {
            const meetingStartTime = new Date(meeting.startDateTime).getTime();
            const meetingEndTime = new Date(meeting.endDateTime).getTime();

            if (forStartTime) {
                if (
                    selectedTime >= meetingStartTime &&
                    selectedTime < meetingEndTime
                ) {
                    this.form.get('startTime')?.setErrors({ conflict: true });
                }
            } else {
                if (
                    selectedTime > meetingStartTime &&
                    selectedTime <= meetingEndTime
                ) {
                    this.form.get('endTime')?.setErrors({ conflict: true });
                }
            }
        });
    }

    async getOrganizers() {
        try {
            const res = await this._sessionService.getPublicOrganizers(
                this.organizaerQuery
            );
            if (res && this._utils.isData<string[]>(res)) {
                this.organizers = res;
                this.setupOrganizerFiltering();
            } else {
                this.organizers = [];
            }
        } catch (error) {
            console.error(error);
            this.organizers = [];
        }
    }

    setupOrganizerFiltering(): void {
        this.filterdOrganizer = this.organizerControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterOrganizer(value))
        );
    }

    onOrganizerTouched(): void {
        this.organizerControl.markAsTouched();
    }

    _filterOrganizer(value: string): string[] {
        const filterValue = value?.toLowerCase();
        return this.organizers.filter((option) =>
            option?.toLowerCase().includes(filterValue)
        );
    }

    async onSubmitHandler(form: FormGroup) {
        if (form.valid) {
            const data = {
                startDateTime: this.roundDateTimeToNextTenMinutes(
                    this.form.get('startDateTime').value
                ),
                endDateTime: this.roundDateTimeToNextTenMinutes(
                    this.form.get('endDateTime').value
                ),
                subject: form.get('subject').value,
                organizer: this.organizerControl?.value,
                organization: this.routes[1],
                room: this.getDeviceName(this.routes[2]),
                participants: [],
            };

            this.participants.controls.forEach((control) => {
                const email = control.get('email')?.value;
                if (email) {
                    data.participants.push(email);
                }
            });

            try {
                const res = await this._sessionService.createPublicSession(
                    data
                );

                if (res) {
                    this.handleSubmitSuccuss(res);
                }
            } catch (error) {
                console.error(error);
                this.handleSubmitError(error);
            }
        } else {
            form.markAllAsTouched();
        }
    }

    async handleSubmitSuccuss(session: any) {
        const dialogRef = this._dialog.open(BookMeetingDialogComponent, {
            data: {
                item: session,
            },
            panelClass: 'custom-book-meeting-dialog',
            maxWidth: '90vw',
        });
        const result = await dialogRef.afterClosed().toPromise();

        if (result?.confirmed) {
            this.getSessions(this.sessionQuery);
            this.onClearForm();
        }
    }

    onClearForm() {
        this.getCurrentTime();
        this.organizerControl.reset();
        this.formGroupDirective.resetForm();
        this.form.reset({
            startTime: this.currentTime,
            startDateTime: this.currentDateTime,
        });
        this.sessionQuery['date'] = new Date();
        this.participants.clear();

        this.setupOrganizerFiltering();
    }

    handleSubmitError(error: any) {
        if (error.message === 'ConflictSession') {
            this._snackBar.openSnackBar(
                this._translateService.instant(
                    'user_role_page.conflict_session'
                ),
                'OK',
                2000,
                'center',
                'top'
            );
        } else {
            this._snackBar.openSnackBar(
                this._translateService.instant(error),
                'OK',
                2000,
                'center',
                'top'
            );
        }
    }

    getTimeFn(date: any) {
        const currentValue = new Date(date).toLocaleTimeString('en', {
            timeStyle: 'short',
            hour12: true,
        });

        const time = currentValue.replace(/\s/g, '');

        return time;
    }

    getDeviceName(urlText: string) {
        return decodeURIComponent(urlText);
    }

    getRoundedDate(date: Date): Date {
        const minutes = date.getMinutes();
        const roundedMinutes = Math.ceil(minutes / 10) * 10;
        const roundedDate = new Date(date);

        if (roundedMinutes === 60) {
            roundedDate.setHours(roundedDate.getHours() + 1);
            roundedDate.setMinutes(0);
        } else {
            roundedDate.setMinutes(roundedMinutes);
        }

        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);
        return roundedDate;
    }

    roundTimeToNextTenMinutes(time: string) {
        const [hour, minute] = time.split(':').map(Number);

        const roundedMinutes = Math.ceil(minute / 10) * 10;
        const adjustedHour = roundedMinutes === 60 ? hour + 1 : hour;
        const finalMinutes = roundedMinutes === 60 ? 0 : roundedMinutes;

        const formattedTime = `${adjustedHour}:${finalMinutes
            .toString()
            .padStart(2, '0')}`;

        return formattedTime;
    }

    addOneHour(time) {
        let [timePart, period] = time.split(' ');
        let [hours, minutes] = timePart.split(':').map(Number);
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        hours += 1;
        if (hours === 24) {
            hours = 0;
        }
        period = hours < 12 ? 'AM' : 'PM';

        if (hours > 12) {
            hours -= 12;
        } else if (hours === 0) {
            hours = 12;
        }

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    dateTimeAddOneHour(dateTime) {
        const date = new Date(dateTime);
        date.setHours(date.getHours() + 1);

        return date;
    }

    roundDateTimeToNextTenMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        minutes = Math.ceil(minutes / 10) * 10;

        if (minutes === 60) {
            minutes = 0;
            hours += 1;

            if (hours === 24) {
                hours = 0;
                date.setDate(date.getDate() + 1);
            }
        }
        date.setHours(hours, minutes, 0, 0);
        return date;
    }
}
