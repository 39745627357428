import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
    constructor(readonly _translateService: TranslateService) {
        super();
        this.translateLabels();
        this._translateService.onLangChange.subscribe(() => {
            this.translateLabels();
            this.changes.next();
        });
    }

    translateLabels() {
        this.itemsPerPageLabel =
            this._translateService.instant('Items per page:');
    }
}
