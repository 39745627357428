<div class="meeting">
    <div class="navbar">
        <img src="../../../assets/images/polyview.png" alt="" />
    </div>
    <div class="meeting-container">
        <div class="meeting-form-container">
            <div class="meeting-form">
                <div class="title">
                    {{ "កក់បន្ទប់" | translate }} {{ getDeviceName(routes[2]) }}
                </div>
                <form
                    [formGroup]="form"
                    (submit)="onSubmitHandler(form)"
                    id="meeting"
                >
                    <div class="form-container">
                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-form-field
                                    class="custom-meeting-input full-width"
                                    appearance="outline"
                                >
                                    <mat-label>{{
                                        "កាលបរិច្ឆេទ" | translate
                                    }}</mat-label>
                                    <input
                                        [min]="currentDateTime"
                                        matInput
                                        [matDatepicker]="startPicker"
                                        formControlName="startDateTime"
                                    />
                                    <mat-datepicker-toggle
                                        matIconSuffix
                                        [for]="startPicker"
                                    ></mat-datepicker-toggle>

                                    <mat-datepicker
                                        #startPicker
                                    ></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12 col-sm-6 mb-2">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width time-field"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "ម៉ោងចាប់ផ្ដើម" | translate
                                        }}</mat-label>
                                        <input
                                            [min]="currentTime"
                                            matInput
                                            [ngxMatTimepicker]="startTimePicker"
                                            formControlName="startTime"
                                        />
                                        <mat-icon
                                            matSuffix
                                            (click)="startTimePicker.open()"
                                            >keyboard_arrow_down</mat-icon
                                        >
                                        <ngx-mat-timepicker
                                            [minutesGap]="10"
                                            #startTimePicker
                                        ></ngx-mat-timepicker>
                                        @if (form.get('startTime').touched &&
                                        form .get('startTime')
                                        .hasError('required')) {
                                        <mat-error>
                                            {{
                                                "ទាមទារម៉ោងចាប់ផ្ដើម"
                                                    | translate
                                            }}
                                        </mat-error>
                                        } @if ( form.get('startTime')
                                        .hasError('conflict')) {
                                        <mat-error>
                                            {{ "ជាន់ការប្រជុំ" | translate }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>

                            <div class="col-12 col-sm-6">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width time-field"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "ម៉ោងបញ្ចប់" | translate
                                        }}</mat-label>
                                        <input
                                            [min]="
                                                this.form.get('startTime').value
                                            "
                                            matInput
                                            [ngxMatTimepicker]="endTimePicker"
                                            formControlName="endTime"
                                        />

                                        <mat-icon
                                            matSuffix
                                            (click)="endTimePicker.open()"
                                            >keyboard_arrow_down</mat-icon
                                        >
                                        <ngx-mat-timepicker
                                            [minutesGap]="10"
                                            #endTimePicker
                                        ></ngx-mat-timepicker>
                                        @if ( form.get('endTime')
                                        .hasError('required')) {
                                        <mat-error>
                                            {{ "ទាមទារការប្រជុំ" | translate }}
                                        </mat-error>
                                        } @if (
                                        form.get('endTime').hasError('conflict'))
                                        {
                                        <mat-error>
                                            {{ "ជាន់ការប្រជុំ" | translate }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "user_role_page.form.subject"
                                                | translate
                                        }}</mat-label>
                                        <input
                                            matInput
                                            type="text"
                                            formControlName="subject"
                                        />
                                        @if (form.get('subject').touched && form
                                        .get('subject') .hasError('required')) {
                                        <mat-error>
                                            {{
                                                "user_role_page.form.errors.subject_required"
                                                    | translate
                                            }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12">
                                <app-input-field>
                                    <mat-form-field
                                        class="full-width"
                                        appearance="outline"
                                    >
                                        <mat-label>{{
                                            "user_role_page.form.organizer"
                                                | translate
                                        }}</mat-label>
                                        <input
                                            matInput
                                            [formControl]="organizerControl"
                                            [matAutocomplete]="organizer"
                                            (blur)="onOrganizerTouched()"
                                        />
                                        <mat-autocomplete
                                            #organizer="matAutocomplete"
                                        >
                                            @for (item of filterdOrganizer |
                                            async; track $index) {
                                            <mat-option [value]="item">
                                                {{ item }}
                                            </mat-option>
                                            }
                                        </mat-autocomplete>
                                        @if
                                        (organizerControl.hasError('required'))
                                        {
                                        <mat-error>
                                            {{
                                                "user_role_page.form.errors.organizer_required"
                                                    | translate
                                            }}
                                        </mat-error>
                                        }
                                    </mat-form-field>
                                </app-input-field>
                            </div>
                        </div>

                        <div class="row participant-container">
                            <div class="col-12">
                                <div class="header">
                                    <div class="part-list">អ្នកចូលរួម</div>
                                    <button
                                        type="button"
                                        mat-flat-button
                                        color="primary"
                                        class="default-btn rounded"
                                        (click)="addParticipant()"
                                    >
                                        <mat-icon>add</mat-icon>
                                        <span>{{ "បញ្ចូល" | translate }}</span>
                                    </button>
                                </div>
                            </div>
                            @if (participants.controls.length === 0) {
                            <div class="empty-participant">
                                <mat-icon svgIcon="emptyz"></mat-icon>
                                <p>គ្មានអ្នកចូលរួម</p>
                            </div>
                            }@else {
                            <div formArrayName="participants" class="content">
                                <div
                                    *ngFor="
                                        let participant of participants.controls;
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                    class="participant-list"
                                >
                                    <span>{{ i + 1 }}.</span>
                                    <div class="participant-form">
                                        <app-input-field>
                                            <mat-form-field
                                                class="full-width"
                                                appearance="outline"
                                            >
                                                <mat-label>{{
                                                    "អ៊ីម៉ែល" | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    type="email"
                                                    formControlName="email"
                                                />

                                                @if
                                                (participant.get('email').hasError('email'))
                                                {
                                                <mat-error>{{
                                                    "អ៊ីម៉ែលមិនត្រឹមត្រូវ"
                                                        | translate
                                                }}</mat-error>
                                                }
                                            </mat-form-field>
                                        </app-input-field>
                                    </div>

                                    <mat-icon
                                        svgIcon="delete"
                                        (click)="removeParticipant(i)"
                                        >delete</mat-icon
                                    >
                                </div>
                            </div>
                            }
                        </div>

                        <div class="button-container">
                            <button
                                type="submit"
                                form="meeting"
                                mat-flat-button
                                color="primary"
                                class="default-btn rounded"
                            >
                                <span>{{ "បង្កើតការប្រជុំ" | translate }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="existing-meeting-container">
            <div class="header">
                <div class="title">
                    {{ "user_role_page.existingMeeting" | translate }}
                </div>
                <div class="date">
                    {{ form.get("startDateTime").value | date }}
                </div>
            </div>

            @if (isLoading && !hasFetched) {
            <div></div>
            } @if (!isLoading && hasFetched) { @if (meetings.length > 0) {
            <div class="content">
                @for (meeting of meetings; track $index) {
                <div class="card">
                    <div class="header">
                        <p class="time">
                            {{ getTimeFn(meeting.startDateTime) }} ~
                            {{ getTimeFn(meeting.endDateTime) }}
                        </p>
                        <p class="subject">{{ meeting.subject }}</p>
                    </div>
                    <div class="footer-container">
                        <div class="room">
                            <mat-icon>meeting_room</mat-icon>
                            <p>{{ getDeviceName(meeting.room) }}</p>
                        </div>
                        <div class="organizer">
                            <mat-icon>person</mat-icon>
                            <p>{{ meeting.organizer }}</p>
                        </div>
                    </div>
                </div>
                }
            </div>
            }@if(meetings.length === 0) {
            <div class="content">
                <div class="empty">
                    <img src="../../../assets/images/no_data.png" alt="" />
                    <p>{{ "user_role_page.noExistingMeeting" | translate }}</p>
                </div>
            </div>
            } }
        </div>
    </div>
</div>
