import { Routes } from '@angular/router';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { MenuItem } from './models/menu-item';
import { PermissionEnum } from './models/enums/permission-enum';
import { noNeedLoginGuard } from './guards/no-need-login.guard';
import { UserRoleEnum } from './models/enums/user-role-enum';
import { NotFoundComponent } from './routes/not-found/not-found.component';
import { MeetingPageComponent } from './routes/meeting-page/meeting-page.component';

export const routes: Routes = [
    {
        path: '',
        component: MainContainerComponent,
        children: [
            {
                path: 'dashboard',
                data: {
                    type: 'dashboard',
                    isSub: false,
                    index: 1,
                    roles: [UserRoleEnum.SYSTEM_ADMIN, UserRoleEnum.ADMIN],
                    icon: 'dashboard',
                },
                loadChildren: () =>
                    import('./routes/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'sessions',
                data: {
                    type: 'sessions',
                    isSub: false,
                    index: 1,
                    roles: [UserRoleEnum.ADMIN],
                    icon: 'calendar_month',
                },
                loadChildren: () =>
                    import('./routes/sessions/sessions.module').then(
                        (m) => m.SessionsModule
                    ),
            },
            {
                path: 'display-admin',
                data: {
                    type: 'display-admin',
                    isSub: false,
                    index: 1,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'smart_display',
                },
                loadChildren: () =>
                    import('./routes/tvs/tvs.module').then((m) => m.TvsModule),
            },
            {
                path: 'display',
                data: {
                    type: 'display',
                    isSub: false,
                    index: 12,
                    roles: [],
                    icon: 'smart_display',
                },
                loadChildren: () =>
                    import('./routes/tvs/tvs.module').then((m) => m.TvsModule),
            },
            // {
            //     path: 'templates',
            //     data: {
            //         type: 'templates',
            //         isSub: false,
            //         index: 3,
            //         roles: [UserRoleEnum.SYSTEM_ADMIN],
            //         icon: 'space_dashboard',
            //     },
            //     loadChildren: () =>
            //         import('./routes/template/template.module').then(
            //             (m) => m.TemplateModule
            //         ),
            // },
            {
                path: 'client',
                data: {
                    type: 'client',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'how_to_reg',
                },
                loadChildren: () =>
                    import('./routes/client/client.module').then(
                        (m) => m.ClientModule
                    ),
            },
            {
                path: 'organization',
                data: {
                    type: 'organization',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'store',
                },
                loadChildren: () =>
                    import('./routes/organizations/organizations.module').then(
                        (m) => m.OrganizationsModule
                    ),
            },

            {
                path: 'subscriptions',
                data: {
                    type: 'subscriptions',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'notifications',
                },
                loadChildren: () =>
                    import('./routes/subscription/subscription.module').then(
                        (m) => m.SubscriptionModule
                    ),
            },

            {
                path: 'devices',
                data: {
                    type: 'devices',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'screenshot_monitor',
                },
                loadChildren: () =>
                    import('./routes/licenses/licenses.module').then(
                        (m) => m.LicensesModule
                    ),
            },
            {
                path: 'organization-admin',
                data: {
                    type: 'organization-admin',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'groups',
                },
                loadChildren: () =>
                    import('./routes/user-module/user-module.module').then(
                        (m) => m.UserModuleModule
                    ),
            },
            {
                path: 'subscription-plan',
                data: {
                    type: 'subscription_plan',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'currency_exchange',
                },
                loadChildren: () =>
                    import(
                        './routes/subscription-plan/subscription-plan.module'
                    ).then((m) => m.SubscriptionPlanModule),
            },
            // {
            //     path: 'tokens',
            //     data: {
            //         type: 'tokens',
            //         isSub: false,
            //         index: 4,
            //         roles: [UserRoleEnum.SYSTEM_ADMIN],
            //         icon: 'keys',
            //     },
            //     loadChildren: () =>
            //         import('./routes/permission/permission.module').then(
            //             (m) => m.PermissionModule
            //         ),
            // },
            {
                path: 'widgets',
                data: {
                    type: 'widgets',
                    isSub: false,
                    index: 4,
                    roles: [UserRoleEnum.SYSTEM_ADMIN],
                    icon: 'widgets',
                },
                loadChildren: () =>
                    import('./routes/widgets/widgets.module').then(
                        (m) => m.WidgetsModule
                    ),
            },

            {
                path: 'manual',
                data: {
                    type: 'manual',
                    isSub: false,
                    index: 14,
                    roles: [],
                    icon: 'picture_as_pdf',
                },
                loadChildren: () =>
                    import('./routes/manual/manual.module').then(
                        (m) => m.ManualModule
                    ),
            },
            {
                path: 'meeting',
                data: {
                    type: 'meetings',
                    isSub: false,
                    index: 1,
                    roles: [],
                    icon: 'calendar_month',
                },
                loadChildren: () =>
                    import('./routes/meeting/meeting.module').then(
                        (m) => m.MeetingModule
                    ),
            },
            {
                path: 'documents',
                data: {
                    type: 'documents',
                    isSub: false,
                    index: 2,
                    roles: [],
                    icon: 'description',
                },
                loadChildren: () =>
                    import('./routes/documents/documents.module').then(
                        (m) => m.DocumentsModule
                    ),
            },
            {
                path: 'meeting-requests',
                data: {
                    type: 'meeting-requests',
                    isSub: false,
                    index: 4,
                    roles: [],
                    icon: 'mark_email_unread',
                },
                loadChildren: () =>
                    import(
                        './routes/meeting-request/meeting-request.module'
                    ).then((m) => m.MeetingRequestModule),
            },
            {
                path: 'missions',
                data: {
                    type: 'missions',
                    isSub: false,
                    index: 3,
                    roles: [],
                    icon: 'flag',
                },
                loadChildren: () =>
                    import('./routes/missions/missions.module').then(
                        (m) => m.MissionsModule
                    ),
            },
            {
                path: 'leave-requests',
                data: {
                    type: 'leave-requests',
                    isSub: false,
                    index: 5,
                    roles: [],
                    icon: 'forward_to_inbox',
                },
                loadChildren: () =>
                    import('./routes/leave-request/leave-request.module').then(
                        (m) => m.LeaveRequestModule
                    ),
            },
            {
                path: 'announcements',
                data: {
                    type: 'announcements',
                    isSub: false,
                    index: 4,
                    roles: [],
                    icon: 'campaign',
                },
                loadChildren: () =>
                    import('./routes/announcements/announcements.module').then(
                        (m) => m.AnnouncementsModule
                    ),
            },
            {
                path: 'leaders',
                data: {
                    type: 'leaders',
                    isSub: false,
                    index: 6,
                    roles: [],
                    icon: 'person',
                },
                loadChildren: () =>
                    import('./routes/leaders/leaders.module').then(
                        (m) => m.LeadersModule
                    ),
            },
            {
                path: 'offices',
                data: {
                    type: 'offices',
                    isSub: false,
                    index: 7,
                    roles: [],
                    icon: 'home_work',
                },
                loadChildren: () =>
                    import('./routes/office/office.module').then(
                        (m) => m.OfficeModule
                    ),
            },
            {
                path: 'officers',
                data: {
                    type: 'officers',
                    isSub: false,
                    index: 8,
                    roles: [],
                    icon: 'supervisor_account',
                },
                loadChildren: () =>
                    import('./routes/office-admin/office-admin.module').then(
                        (m) => m.OfficeAdminModule
                    ),
            },
            {
                path: 'buildings',
                data: {
                    type: 'buildings',
                    isSub: false,
                    index: 9,
                    roles: [],
                    icon: 'corporate_fare',
                },
                loadChildren: () =>
                    import('./routes/buildings/buildings.module').then(
                        (m) => m.BuildingsModule
                    ),
            },
            {
                path: 'floors',
                data: {
                    type: 'floors',
                    isSub: false,
                    index: 10,
                    roles: [],
                    icon: 'layers',
                },
                loadChildren: () =>
                    import('./routes/floors/floors.module').then(
                        (m) => m.FloorsModule
                    ),
            },
            {
                path: 'rooms',
                data: {
                    type: 'rooms',
                    isSub: false,
                    index: 11,
                    roles: [],
                    icon: 'camera_outdoor',
                },
                loadChildren: () =>
                    import('./routes/rooms/rooms.module').then(
                        (m) => m.RoomsModule
                    ),
            },
            {
                path: 'reports',
                data: {
                    type: 'reports',
                    isSub: false,
                    index: 12,
                    roles: [],
                    icon: 'summarize',
                },
                loadChildren: () =>
                    import('./routes/reports/reports.module').then(
                        (m) => m.ReportsModule
                    ),
            },
            {
                path: 'users',
                data: {
                    type: 'users',
                    isSub: false,
                    index: 11.1,
                    roles: [UserRoleEnum.ADMIN],
                    icon: 'group',
                },
                loadChildren: () =>
                    import('./routes/users/users.module').then(
                        (m) => m.UsersModule
                    ),
            },
            // {
            //     path: 'admin_manual',
            //     data: {
            //         type: 'admin_manual',
            //         isSub: false,
            //         index: 14,
            //         roles: [UserRoleEnum.SYSTEM_ADMIN],
            //         icon: 'picture_as_pdf',
            //     },
            //     loadChildren: () =>
            //         import('./routes/admin-manual/admin-manual.module').then(
            //             (m) => m.AdminManualModule
            //         ),
            // },
            {
                path: 'form-template',
                data: {
                    type: 'form-template',
                    isSub: false,
                    show: false,
                    icon: '',
                    index: 4,
                    roles: [],
                },
                loadChildren: () =>
                    import(
                        './routes/annual-investment-plans/annual-investment-plans.module'
                    ).then((m) => m.AnnualInvestmentPlansModule),
            },
        ],
    },

    {
        path: 'book-room/:organization_id/:room_id',
        component: MeetingPageComponent,
    },

    {
        path: 'login',
        canActivate: [noNeedLoginGuard],
        loadChildren: () =>
            import('./routes/login/login.module').then((m) => m.LoginModule),
    },

    {
        path: '**',
        component: NotFoundComponent,
    },
];
export const MENUITEMS: MenuItem[] = routes[0].children!.map((routeItem) => {
    return {
        route: routeItem.path,
        type: routeItem.data?.type,
        permissions: routeItem.data?.permissions as PermissionEnum[],
        title: routeItem.path.split('/').pop(),
        icon: routeItem.data?.icon,
        iconType: routeItem.data?.iconType,
        isSub: routeItem.data?.isSub,
        index: routeItem.data?.index ?? 0,
        show: routeItem.data?.show,
        roles: routeItem.data?.roles,
    } as MenuItem;
});
