import { Component, OnInit, Inject } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../services/auth.service';
import { BaseDialogData } from '../../../../models/bases/base-dialog-data';
import { BaseApiResponse } from '../../../../models/bases/base-api-response';
import { Utils } from '../../../../utils/utils';

@Component({
    selector: 'app-user-change-pwd-form',
    templateUrl: './user-change-pwd-form.component.html',
    styleUrl: './user-change-pwd-form.component.scss',
})
export class UserChangePwdFormComponent implements OnInit {
    id: string = '';
    showconfirmPwd: boolean = false;
    showPwd: boolean = false;
    form_password = new FormGroup(
        {
            newPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
            confirmPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
        },
        { validators: this.matchValidator('newPassword', 'confirmPassword') }
    );
    matchValidator(
        controlName: string,
        matchingControlName: string
    ): ValidatorFn {
        return (formGroup: AbstractControl) => {
            const control = formGroup.get(controlName);
            const matchingControl = formGroup.get(matchingControlName);

            if (!control || !matchingControl) {
                return null; // If the controls are not found, don't validate
            }

            if (
                matchingControl.errors &&
                !matchingControl.errors['mustMatch']
            ) {
                return null; // If another validator has already marked this as invalid
            }

            // Set an error if the values don't match
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null); // Clear the error if they match
            }

            return null;
        };
    }

    constructor(
        readonly _dialogRef: MatDialogRef<UserChangePwdFormComponent>,
        readonly userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.id = data;
    }
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
    confirm(): void {
        this._dialogRef.close({ confirmed: true });
    }

    closeDialog(): void {
        this._dialogRef.close();
    }
    onSubmit = async () => {
        if (
            this.form_password.value.confirmPassword ===
            this.form_password.value.newPassword
        ) {
            const body = {
                password: this.form_password.value.newPassword,
            };
            if (this.form_password.valid) {
                await this.userService.resetUserPassword(this.id, body);
                this._dialogRef.close();
            } else {
                console.log('Form is invalid!');
            }
        } else {
            return;
        }
    };
    toggleShowPassword = () => {
        this.showPwd = !this.showPwd;
    };
    toggleShowconfirmPassword = () => {
        this.showconfirmPwd = !this.showconfirmPwd;
    };
}
