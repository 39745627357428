import {
    Component,
    HostListener,
    LOCALE_ID,
    OnInit,
    Provider,
} from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MENUITEMS } from '../../app.routes';
import { MenuItem } from '../../models/menu-item';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageEnum } from '../../models/enums/local-storage-enum';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { BaseDialogData } from '../../models/bases/base-dialog-data';
import { DomSanitizer } from '@angular/platform-browser';
import { UserChangePwdFormComponent } from '../../routes/users/components/user-change-pwd-form/user-change-pwd-form.component';
import { NgIf } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationModel } from '../../models/organization';
import { MatRipple } from '@angular/material/core';
import { lang } from 'moment';
@Component({
    selector: 'app-main-container',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        RouterModule,
        TranslateModule,
        MatExpansionModule,
        MatListModule,
        MatMenuModule,
        NgIf,
        MatRipple,
    ],
    templateUrl: './main-container.component.html',
    styleUrl: './main-container.component.scss',
})
export class MainContainerComponent implements OnInit {
    currentLanguage: string = 'English'; // Default language
    username: string;
    role: string;
    opened: boolean = true;
    organzation: OrganizationModel = null;
    menu: any = {};
    menuList: MenuItem[] = [];
    settingsMenu: MenuItem[];
    desktopViewWidth: number = 1100;
    drawerMode: 'over' | 'side' = 'side';

    @HostListener('window:resize', ['$event.target.innerWidth'])
    onResize(width: number) {
        if (width < this.desktopViewWidth) {
            this.drawerMode = 'over';
            if (this.opened) {
                this.opened = false;
            }
        } else {
            this.drawerMode = 'side';
            if (!this.opened) {
                this.opened = true;
            }
        }
    }

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        readonly _authService: AuthService,
        readonly _localStorageService: LocalStorageService,
        readonly _router: Router,
        readonly _dialog: MatDialog,
        readonly snackBar: MatSnackBar,
        readonly translate: TranslateService
    ) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
        iconRegistry.addSvgIcon(
            'home',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home.svg')
        );
        iconRegistry.addSvgIcon(
            'about',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/about.svg')
        );
        iconRegistry.addSvgIcon(
            'account',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/account.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'accountant',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/accountant.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'carbon_report',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/carbon_report.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'dollar_cycle',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/dollar_cycle.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'investment_plan',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/investment-plan.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'invest_dollar',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/invest_dollar.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'request',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/request.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'settings',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/solar-settings-linear.svg'
            )
        );
    }

    ngOnInit(): void {
        this.userDetailes();
        this.languageSettings();
        this.populateMenuList();
        this.redirectToFirstAvailableMenu();
    }

    userDetailes() {
        this.username = this._localStorageService.get(
            LocalStorageEnum.username
        );
        this.role = this._localStorageService.get(LocalStorageEnum.user_role);
        this.organzation = this._authService.organization;
    }

    languageSettings() {
        const savedLanguage = this._localStorageService.get(
            LocalStorageEnum.language
        );

        this.currentLanguage = savedLanguage === 'km' ? 'Khmer' : 'English';
        this.translate.use(savedLanguage || 'en');
    }

    populateMenuList() {
        MENUITEMS.forEach((item) => {
            if (this.isMenuItemVisible(item)) {
                this.menuList.push(item);
            }
        });

        this.menuList.sort((a, b) => a.index - b.index);
    }

    isMenuItemVisible(item: MenuItem): boolean {
        if (!item.roles.includes(this.role) || item.show === false) {
            return false;
        }

        if (this.role === 'systemAdmin') {
            return true;
        }

        const organizationSettings = {
            meetings: this.organzation.useMeeting,
            missions: this.organzation.useMission,
            leaders: this.organzation.useLeader,
            'leave-requests': this.organzation.useLeave,
            announcements: this.organzation.useAnnouncement,
            'meeting-requests': this.organzation.useMeetingRequest,
            officers: this.organzation.useMeetingRequest,
            offices: this.organzation.useMeetingRequest,
            documents: this.organzation.useDocument,
            users: this.organzation.useMobile,
            reports: this.organzation.useReport,
            tokens: this.organzation.manageAuthorization,
            display: this.organzation.showTvLink,
        };

        return organizationSettings[item.type] !== 'no';
    }

    redirectToFirstAvailableMenu() {
        if (this._router.url === '/') {
            const firstAvailableMenu = MENUITEMS.find((item) =>
                item.permissions?.some((permission) =>
                    this._authService.permissions.includes(permission)
                )
            );

            const route = firstAvailableMenu
                ? firstAvailableMenu.route
                : '/dashboard';
            this._router.navigate([route], { replaceUrl: true });
        }
    }

    toggleMenu() {
        this.opened = !this.opened;
    }

    userChangePwdHandler = async () => {
        try {
            const dialogData: BaseDialogData = {
                isForUpdate: false,
            };
            const dialogRef = this._dialog.open(UserChangePwdFormComponent, {
                width: '600px',
                disableClose: true,
                data: dialogData,
                panelClass: 'custom-dialog',
            });
            const result = await lastValueFrom(dialogRef.afterClosed());
            if (result?.succeeded) {
                console.log('userChangePwd succeeded => ', result.succeeded);
                this._authService.logout();
            } else {
                console.log('user change pwd error');
            }
        } catch (error) {
            console.log('user change pwd error->', error);
            this.snackBar.open('Change password Error', 'Close', {
                duration: 2000,
            });
        }
    };

    logoutHandler() {
        this._authService.logout();
    }

    switchLanguage(language: string): void {
        if (language === 'en') {
            this.currentLanguage = 'English';
        } else if (language === 'km') {
            this.currentLanguage = 'Khmer';
        }
        this.translate.use(language);
        this._localStorageService.set(LocalStorageEnum.language, language);
    }
}
