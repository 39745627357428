import {
    ApplicationConfig,
    LOCALE_ID,
    Provider,
    importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import km from '@angular/common/locales/km';
import { MatDateFormats } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

registerLocaleData(km);
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './services/custom-paginator-intl-service.service';
import { LocalStorageEnum } from './models/enums/local-storage-enum';
import { LocalStorageService } from './services/local-storage.service';
import { lang } from 'moment';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};
// const localeProvider: Provider = {
//     provide: LOCALE_ID,
//     useValue: LocalStorageEnum.language,
// };
export function localeProviderFactory(
    localStorageService: LocalStorageService
): string {
    const language =
        localStorageService.get(LocalStorageEnum.language) || 'km-KH'; // Default to 'km-KH'
    return language;
}

// Provider setup for LOCALE_ID using the factory function
export const localeProvider: Provider = {
    provide: LOCALE_ID,
    useFactory: localeProviderFactory,
    deps: [LocalStorageService], // Inject LocalStorageService here
};
export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideAnimationsAsync(),
        provideHttpClient(),
        {
            provide: MatPaginatorIntl,
            useClass: CustomPaginatorIntl,
        },
        importProvidersFrom([
            TranslateModule.forRoot({
                defaultLanguage: 'km',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ]),
        localeProvider,
        provideMomentDateAdapter(MY_FORMATS),
    ],
};
