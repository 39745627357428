import { Component, inject } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarModule,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
    selector: 'app-custom-snackbar',
    standalone: true,
    imports: [MatSnackBarModule],
    templateUrl: './custom-snackbar.component.html',
    styleUrl: './custom-snackbar.component.scss',
})
export class CustomSnackbarComponent {
    readonly _snackBar = inject(MatSnackBar);

    openSnackBar(
        message: string,
        action: string = 'Close',
        duration: number = 2500,
        horizontalPosition: MatSnackBarHorizontalPosition = 'center',
        verticalPosition: MatSnackBarVerticalPosition = 'bottom'
    ) {
        const config: MatSnackBarConfig = {
            duration,
            horizontalPosition,
            verticalPosition,
        };
        this._snackBar.open(message, action, config);
    }
}
