<div>
    <div mat-dialog-title>
        <div class="title-container">
            <div class="custom-background-icon">
                <mat-icon class="custom-icon-color">edit</mat-icon>
            </div>
            <p>{{ "actions.change_pwd" | translate }}</p>
        </div>
    </div>
    <mat-dialog-content>
        <form [formGroup]="form_password" (ngSubmit)="onSubmit()">
            <div class="mt-4 custom-input-password">
                <mat-form-field
                    appearance="outline"
                    class="custom-input-password"
                >
                    <mat-label>{{
                        "actions.new_password" | translate
                    }}</mat-label>
                    <input
                        formControlName="newPassword"
                        matInput
                        [type]="showPwd ? 'text' : 'password'"
                    />
                    <mat-icon matIconSuffix (click)="toggleShowPassword()">{{
                        !showPwd ? "visibility_off" : "visibility"
                    }}</mat-icon>
                    <mat-error
                        *ngIf="
                            form_password
                                .get('newPassword')
                                .hasError('minlength')
                        "
                    >
                        {{ "actions.password_must_be_at_least" | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form_password
                                .get('newPassword')
                                .hasError('required')
                        "
                    >
                        {{ "actions.password_required" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="w-100 custom-input-password">
                <mat-form-field
                    appearance="outline"
                    class="custom-input-password"
                >
                    <mat-label>{{
                        "actions.confirm_password" | translate
                    }}</mat-label>
                    <input
                        formControlName="confirmPassword"
                        matInput
                        [type]="showconfirmPwd ? 'text' : 'password'"
                    />
                    <mat-icon
                        matIconSuffix
                        (click)="toggleShowconfirmPassword()"
                        >{{
                            !showconfirmPwd ? "visibility_off" : "visibility"
                        }}</mat-icon
                    >
                    <mat-error
                        *ngIf="
                            form_password
                                .get('confirmPassword')
                                .hasError('minlength')
                        "
                    >
                        {{ "actions.password_must_be_at_least" | translate }}
                    </mat-error>

                    <!-- MustMatch Error -->
                    <mat-error
                        *ngIf="
                            form_password
                                .get('confirmPassword')
                                .hasError('mustMatch')
                        "
                    >
                        {{ "actions.password_not_match" | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form_password
                                .get('newPassword')
                                .hasError('required')
                        "
                    >
                        {{ "actions.password_required" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <div class="custom-dialog-action">
        <button
            type="button"
            mat-stroked-button
            class="default-btn rounded"
            (click)="closeDialog()"
            color="primary"
        >
            <mat-icon>close</mat-icon>
            <span> {{ "actions.delete_dialog.cancel" | translate }}</span>
        </button>
        <button
            type="button"
            color="primary"
            mat-flat-button
            class="default-btn rounded"
            (click)="onSubmit()"
        >
            <mat-icon>check</mat-icon>
            <span>Submit</span>
        </button>
    </div>
</div>
