import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SessionModel } from '../../../models/session';
import { BookSessionDialog } from '../../../models/book-session-dialog';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-book-meeting-dialog',
    standalone: true,
    imports: [TranslateModule, MatIconModule, MatButtonModule, DatePipe],
    templateUrl: './book-meeting-dialog.component.html',
    styleUrl: './book-meeting-dialog.component.scss',
})
export class BookMeetingDialogComponent implements OnInit {
    session: SessionModel;

    constructor(
        readonly _dialogRef: MatDialogRef<BookMeetingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: BookSessionDialog
    ) {}

    ngOnInit(): void {
        this.session = {
            room: this.dialogData.item.room,
            subject: this.dialogData.item.subject,
            startDateTime: this.dialogData.item.startDateTime,
            endDateTime: this.dialogData.item.endDateTime,
            organizer: this.dialogData.item.organizer,
        };
    }

    confirm(): void {
        this._dialogRef.close({ confirmed: true });
    }

    getTimeFn(date: any) {
        const currentValue = new Date(date).toLocaleTimeString('en', {
            timeStyle: 'short',
            hour12: true,
        });

        const time = currentValue.replace(/\s/g, '');

        return time;
    }
}
